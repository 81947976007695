import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import ImageCard from "../components/image-card"

export default function About() {
  return ( 
    <Layout>
        <section className="container">
        <h1>About Leeds City Juniors</h1>
        <InnerPageTwitterLayout>
          <img className="img-fluid mb-4 inner-page-hero" src="full-squad-with-logo.jpg" />
          <div>
          <p>Leeds City FC was formed in 2006 from the merger of Adel FC, Abbey Grange Old Boys FC and Leeds City Vixens FC.</p>
          <p>Leeds City Juniors was formed at the same time with the junior teams of Abbey Grange Old Boys FC and a junior girls team from Leeds City Vixens. The junior section of Abbey Grange had originally been formed in 2003 by Mark McFarlane (now Head of the Senior Section and manager of the Leeds City Academy side) and Dave Booth (who was treasurer of the Juniors until 2010). The first ever match of the junior section was a two team mini soccer U9 fixture in September 2003 at Bramley – from the two teams that day Chris Moseley, James McFarlane, Adam Potter and Alex Comery have progressed to play in the Leeds City Academy side or higher. James King and David Stevenson, who joined the U9s later in that first season also played in the Academy side in 2012/13.</p>
          <p>From two U9 teams and 24 players on the LMU Bedquilts mini soccer pitch in the 2003/04 season the juniors expanded rapidly with additional age groups added each season. Soon 11 a side football was being played on the LMU Bedquilts pitches as the original U9 players reached U11 age group and once the merger took place in 2006 the facilities at Adel WMA became available to the junior section.</p>
          <p>In 2006 Leeds City Juniors became an FA Charter Standard Community Club – an affiliation that we proudly still hold to this day.</p>
          <p>Kindergarten &nbsp;was established in 2008 on a Saturday morning for children from 4 to 7 years of age. Wildcats started on Wednesday afternoons for girls from 4 to 11 years of age. Both of these continues to be popular and provide a good fun environment for children to learn and develop prior to being old enough to play in the U8 teams, this also acts as a good feeder into playing club football and maintains the continuity of age groups at the club.</p>
          <p>In the 2020-21 season Leeds City Juniors continue to provide junior football in the Garforth Junior Football League, West Riding Girls Football League and Harrogate Junior Football League to over 250 boys and girls. This is in addition to the children who regularly attend the Kindergarten and Wildcat sessions.</p>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div className="col">
              <ImageCard href="http://www.thefa.com" image="logo-thefa.jpg" imageClass="object-fit-contain">The website for the English football association, the Emirates FA Cup and the England football team.</ImageCard>
            </div>
            <div className="col">
              <ImageCard href="http://www.westridingfa.com/" image="logo-wrcfa.jpg" imageClass="object-fit-contain">West Riding FA supports football at all levels in the County and has successfully developed outstanding business and community partnerships. </ImageCard>
            </div>
            <div className="col">
              <ImageCard href="http://www.garforthleague.co.uk" image="logo-gjfl.jpg" imageClass="object-fit-contain">The Garforth Junior Football League. </ImageCard>
            </div>
            </div>
          </div>
        </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
